* {
  margin: 0;
  padding: 0;
}

.app {
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  background-image: url("./background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.wrapper {
  backdrop-filter: blur(1px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}

.header {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
  user-select: none;
}

.header:hover{
  color: #000000;
}

.header:active{
  color: #ffffff;
}


.title {
  font-size: 55px;
  margin-bottom: 6px;
  color: white;
  text-align: center;
}

.subtitle {
  font-size: 30px;
  color: white;
  text-align: center;

}

.paragraph {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .title {
    font-size: 100px;
    margin-bottom: 6px;
    color: white;
    text-align: center;
  }

  .subtitle {
    font-size: 60px;
    color: white;
    text-align: center;

  }

  .paragraph {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: bold;
  }
}